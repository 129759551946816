<template>
  <div class="add-consultation-wrapper">
    <div class="content-wrapper">
      <div class="card-box">
        <h4 class="title border-bottom-1px">问题描述</h4>
        <p>{{detail.questionContent}}</p>
      </div>
      <div class="card-box" v-if="detail.replyTime">
        <h4 class="title border-bottom-1px">问题回复</h4>
        <p>{{detail.replyContent}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'consultationDetail',
  props: {
    detail: {}
  }
}
</script>

<style lang="scss" scoped>
  .card-box{
    margin-bottom: 20px;
    .title{
      padding-bottom: 28px;
      margin-bottom: 28px;
      font-size: 32px;
      color: #333;
      font-weight: 600;
    }
    p{
      margin-bottom: 24px;
      color: #666;
      font-size: 28px;
    }
  }
</style>
