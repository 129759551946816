import { render, staticRenderFns } from "./consultationDetail.vue?vue&type=template&id=568b3856&scoped=true&"
import script from "./consultationDetail.vue?vue&type=script&lang=js&"
export * from "./consultationDetail.vue?vue&type=script&lang=js&"
import style0 from "./consultationDetail.vue?vue&type=style&index=0&id=568b3856&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "568b3856",
  null
  
)

export default component.exports